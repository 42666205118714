<template>
  <div class="container">
    <div class="topinfo">
      <div class="teacher-box">
        <img class="teacher-img" :src="teacherInfo.image" alt="" />
        <div class="info">
          <div class="name">{{ teacherInfo.name }}</div>
          <div class="college">
            <div
              class="txt"
              v-for="(txt, cIndex) in teacherInfo.positionalTitles"
              :key="cIndex"
            >
              {{ txt }}
            </div>
          </div>
        </div>
      </div>
      <div class="intro">{{ teacherInfo.description }}</div>
    </div>
    <div class="intro-box">
      <div class="title">详细介绍</div>
      <div class="info" v-html="teacherInfo.content"></div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      teacherId: this.$route.query.id,
      teacherInfo: {},
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      this.$http
        .get("/api/wap/teacher/getDetails?teacherId=" + this.teacherId)
        .then((res) => {
          this.teacherInfo = res.data.teacherInfo;
        });
    },
  },
};
</script>

<style lang='less' scoped>
.container {
  padding: 0.3rem;
  background: #f7f7f7;
  .topinfo {
    width: 100%;
    background-color: #fff;
    border-radius: 0.2rem;
    padding: 0.3rem;
    margin-bottom: 0.3rem;
    overflow: hidden;
  }
  .teacher-box {
    display: flex;
    width: 100%;
    padding-bottom: 0.4rem;
    border-bottom: solid 0.01rem #efefef;
    .teacher-img {
      display: block;
      width: 2.8rem;
      height: 3.7rem;
      border-radius: 0.15rem;
      object-fit: cover;
      object-position: center;
    }
    .info {
      width: calc(100% - 2.8rem);
      padding-left: 0.4rem;

      .name {
        font-weight: 700;
        font-size: 0.36rem;
        padding: 0.15rem 0 0.3rem;
        color: #333;
        text-align: left;
        border-bottom: solid 1px #f5f5f5;
      }
      .college {
        width: 100%;
        color: #666;
        text-align: left;
        padding-top: 0.3rem;
        .txt {
          font-size: 0.24rem;
          line-height: 0.4rem;
          color: #666;
          margin-bottom: 0.3rem;
        }
      }
    }
  }
  .intro {
    font-size: 0.28rem;
    line-height: 0.48rem;
    color: #666;
    text-align: left;
    padding-top: 0.2rem;
  }
  .intro-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 0.2rem;
    .title {
      text-align: left;
      padding: 0.3rem 0.3rem;
      font-size: 0.32rem;
      font-weight: 700;
      border-bottom: 1px solid #efefef;
    }
    /deep/.info {
      font-size: 0.28rem;
      line-height: 0.48rem;
      color: #666;
      text-align: left;
      padding: 0.2rem 0.3rem;
      p {
        margin-top: 0 !important;
      }
    }
  }
}
</style>
